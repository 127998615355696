import SEO from "components/seo";
import Layout from "components/layout";
import React from "react";
import NewYork from "components/locationIndexPages/NewYorkCity/index";

function index() {
  const title = "New York City Translation Services - NYC Translation Agency";
  const description =
    "Looking for translation services in New York City? Tomedes provides translation, localization, and interpretation services in 120+ languages with rapid delivery and 24/7 support.";
  const keywords = "Translation Services in NYC";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/nyc-translation-services"
      />
      <NewYork />
    </Layout>
  );
}

export default index;
